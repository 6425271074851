<template>
  <div>
    <CRow>
      <CCol sm="12">
        <ProvidersTable
          :items="providers"
          hover
          striped
          border
          small
          fixed
          caption="Proveedores"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ProvidersTable from '../../components/providers/ProvidersTable.vue'
import providers from '../../services/providers';
import store from '../../store'

export default {
  name: 'Providers',
  components: { ProvidersTable },
  data: function () {
		return {
            providers: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await providers.get();

    if(response.type == "success"){
      this.providers = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.providers = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
